





























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { projectModule } from '@/store'

import Chat from '@/components/list/Chat.vue'
import Tasks from '@/components/list/Tasks.vue'
import AddPlaceholder from './partials/AddPlaceholder.vue'
import ProjectStepTile from './partials/ProjectStepTile.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import Notifications from '@/components/list/Notifications.vue'

import { ProjectResource } from '@/models/projects/ProjectResource'
import { ProcessResource } from '@/models/process/ProcessResource'
import StatusBadge from '@/components/statuses/StatusBadge.vue'

@Component({
  components: {
    StatusBadge,
    Chat,
    Tasks,
    SmallLoader,
    Notifications,
    AddPlaceholder,
    ProjectStepTile,
  },
})
export default class ProjectDashboard extends Vue {
  @Prop()
  private readonly project!: ProjectResource

  private userActiviesTab = 'tasks'
  private projectActiviesTab = 'activity'

  private mounted(): void {
    this.project.tasks.get()
    this.project.activities.get()
  }

  private get processes(): ProcessResource[] {
    return this.project.processes.sort((a, b) => a.order - b.order)
  }

  private goToDocument(): void {
    this.$router.push('/projects/' + this.project.id + '/multi-documents')
  }
}
